<template lang="">
    <div class="card">
        <div class="card-header border-0">
            <div class="card-title w-100 d-flex justify-content-between">
                <h1>Başarılarımız </h1>
                <a href="#"
                data-bs-toggle="modal"
                @click="ourvictory = {}"
                id="marka_ekle_buton"
                data-bs-target="#kt_modal_add_ourvictory" class="btn btn-primary">
                <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni Başarı Ekle</a>
            </div>
        </div>
        <div class="card-body pt-0 pb-5">
            <veri-tablo :datas="datas" :headers="tableHeader" :links="links" :metas="metas">
                <template v-slot:cell-season="{ row: item }">
                    {{ item.season }}
                </template>
                <template v-slot:cell-type="{ row: item }">
                    {{ item.type }}                    
                </template>
                <template v-slot:cell-actions="{row:item}">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link rounded p-3 bg-light">
                            İşlemler<el-icon class="el-icon--right"><arrow-down /></el-icon>
                        </span>
                        <template #dropdown>
                        <el-dropdown-menu>
               
                            <el-dropdown-item >
                                <router-link 
                                    replace
                                    to="yonet">
                                    Yönet
                                 </router-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click="ourvictory = item">
                               <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_update_ourvictory"  style="color:var(--el-text-color-regular);"> Güncelle </a>
                            </el-dropdown-item>
                            <el-dropdown-item @click="deleteItem(item)" >Sil</el-dropdown-item>
                        </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </veri-tablo>
        </div>
    </div>
    <empty-modal modalId="kt_modal_add_ourvictory" ref="eklemodal__ref" modalTitle="Başarı Oluştur"> 
        <template v-slot:ModalBody>
            
            <div class="row">
                <div class="col"> 
                    <div class="row">
                        <div class="col-md-6"> 
                            <div class="input-group mb-5">
                                <label for="" class="form-label">Başarı Dönemi</label>
                                <input type="text  w-100" v-model="ourvictory.season" class="form-control w-100" placeholder="Başarı Dönemi" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                            
                        </div>
                        <div class="col-md-6">
                            <div class="input-group mb-5">     
                                <label for="" class="form-label">Başarı Tipi</label>     
                                <input type="text" style="width:100%;" v-model="ourvictory.type" class="form-control" placeholder="Başarı Tipi" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                        </div>

                    </div>
                    
                </div>
                
            </div>

            
        </template>
        <template v-slot:ModalFooter>
            <a 
                @click="save(ourvictory)"
                class="btn btn-primary"> 
                Kaydet
            </a>
        </template>
    </empty-modal>
    <empty-modal modalId="kt_modal_update_ourvictory" ref="gucellememodal__ref" modalTitle="Başarı Güncelle"> 
        <template v-slot:ModalBody>
            <div class="row">
                <div class="col-md-6"> 
                    <div class="input-group mb-5">
                        <label for="" class="form-label">Başarı Dönemi </label>
                        <input type="text" v-model="ourvictory.season" class="form-control w-100" placeholder="Başarı Dönemi"  aria-describedby="basic-addon1"/>
                    </div>
                </div>
                 <div class="col-md-6"> 
                    <div class="input-group mb-5">
                        <label for="" class="form-label">Başarı Tipi </label>
                        <input type="text" v-model="ourvictory.type" class="form-control w-100" placeholder="Başarı Tipi"  aria-describedby="basic-addon1"/>
                    </div>
                </div>
  
            </div>

        </template>
        <template v-slot:ModalFooter>
            <a 
            id="marka_ekle_buton"
            @click="update(ourvictory)"
            class="btn btn-primary"> 
                Kaydet
            </a>
            <button 
            @click="gucellememodal__ref.close()"
            id="marka_ekle_buton"
            class="btn btn-danger"> 
                Kapat
            </button>
        </template>
    </empty-modal>

</template>
<script>

import { useStore } from "vuex";
import { ArrowDown } from '@element-plus/icons-vue';

import store from '@/store';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { inject } from 'vue';
import  useCruds  from '@/composables/useCrud.js';
import { computed, onMounted ,reactive, ref } from "vue";


export default {
  components: {
    VeriTablo,
    EmptyModal,
    ArrowDown,
 
  },
    setup() {
        const ourvictory = ref({season:'',type:'',});
        const slug = ref("/ourVictories");
        const eklemodal__ref = ref();
        const gucellememodal__ref = ref();

        const imageUrl = ref('');
        const { saveCrud, updateCrud, deleteCrud,loadCrud } = useCruds();

        const tableHeader = [
            {
                name: "Zafer Sezonu",
                key:'season'
            },
            {
                name: "Zafer Tipi",
                key: "type",
            },
            {
                name: "İşlemler",
                key: "actions",
            },
        ];

        const store = useStore();
        const router = useRouter();

        const datas = ref({});
        const metas = ref({});
        const links = ref({});
  
       
        const save = (element) => {
            let payload = {};
            payload.data = element;
            payload.slug = slug.value;

            saveCrud(payload).then(
                function(value) {
                    datas.value.unshift(value.data);
                    eklemodal__ref.value.close();
                }
            );
        };
        const update = (element) => {
            
            let payload = {};
            payload.slug = slug.value+"/"+element.ourVictoryId;
            payload.data = element;
            updateCrud(payload).then(
                function(value) { 
                    gucellememodal__ref.value.close();
                }
            );
        };
        const emitter = inject('emitter');

        const deleteItem = (item) => {
            console.log(item);
            let payload = {};
            payload.slug = slug.value+"/"+item.ourVictoryId;
            deleteCrud(payload).then(function(value){
                if(value){
                    for (let i = 0; i < datas.value.length; i++) {
                        if (datas.value[i] === item) {
                            datas.value.splice(i, 1);
                        }
                    }
                }
            });
            
        };


        function loadPicture(file) {
           
            let img = document.getElementById('img');
            var reader = new FileReader();
            reader.onload = function(e) {
                imageUrl.value = e.target.result;
            }
            reader.readAsDataURL(file);
        }   
        const onImageChange = (file) => {
            loadPicture(file.raw);
        };
        onMounted(() => {
            loadCrud('ourVictories').then(function(response){
                if(response.success){

                    datas.value = response.data;
                    links.value = response.links;
                    metas.value = response.meta;
                }
            });
             emitter.on('PageButtonClicked', (value) => {     
                if(router.currentRoute.value.path == "/ourvictories"){
                    loadCrud('/ourVictories',value).then(function(response){
                        if(response.success){
                            datas.value = response.data;
                            links.value = response.links;
                            metas.value = response.meta;
                        }
                    });
                }
            });

            
        });
       
        return {
            tableHeader,
            datas,
            ourvictory,
            deleteItem,
            save,
            update,
            links,
            metas,
            eklemodal__ref,
            gucellememodal__ref,
            imageUrl,
            onImageChange
        }
    }
}


</script>
<style lang="css">
   .colorful-div{
        width:100px;
        height:24px;
        border-radius:8px;
        padding:5px;
        background:#fff;
        font-family: 'Comfortaa', cursive;
        cursor:pointer;
        color:#fff;
        margin:auto;
   }
   
   .badge{
       width:24px;
       height:24px;
       display:flex;
       justify-content:center;
       align-items:center;
       align-content:center;
   }

    .avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    }

</style>


<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>